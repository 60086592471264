import { IResponse } from "@/models/IClient";
import { IClientPackage } from "@/models/IClientPackage";
import { IClientSettings } from "@/models/IClientSettings";
import { IPackage } from "@/models/IPackage";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class ClientPackageService {
  package!: IClientPackage;
  lstPackage: IClientPackage[] = [];
  responsedata: any;

  savePackage(model: IClientPackage): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "ClientPackage/saveclientpackage", model, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
  getListofPackages(
    pageIndex: number,
    search: string = ""
  ): Result {
    var URL =
      API_URL + "ClientPackage/getpackagebyclientId/" + clientId + "/" + pageIndex;

    if (search.length > 0) {
      URL =
        API_URL +
        "ClientPackage/getpackagebyclientId/" +
        clientId +
        "/" +
        pageIndex +
        "?search=" +
        search;
    }

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader() })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getPackageById(packageId: number): Promise<IClientPackage> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL + "ClientPackage/getpackagebypackageId?packageId=" + packageId + "&clientId=" + clientId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getClietnPackages(branchId: number):Promise<any>{
    this.responsedata = axios
      .get<IResponse>(
        API_URL + "ClientPackage/getlistofclientpackages?clientId=" + clientId + "&branchId=" + branchId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new ClientPackageService();
